import React from 'react';
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import styled from '@emotion/styled';

const StyledFooter = styled(AppBar)(({ theme }) => ({
  padding: theme.spacing(1),
  // position: 'fixed',
  // width: '100%',
  color: theme.palette.common.white,
  // bottom: 0,
  // height: '5vh',
  backgroundColor: theme.palette.common.black,
}));

// const StyledFooter = styled(AppBar)`
//   padding: theme.spacing(1);
//   position: 'absolute';
//   width: '100%';
//   color: theme.palette.common .white;
//   bottom: 0;
//   height: '5vh';
//   background-color: theme.palette.common .black
// `

// const useStyles = makeStyles((theme) => ({
//   footer: {
//     padding: theme.spacing(1);
//     position: 'absolute',
//     width: '100%',
//     color: theme.palette.common.white,
//     bottom: 0,
//     height: '5vh',
//     backgroundColor: theme.palette.common.black
//   },
//   content: {
//     marginBottom: theme.spacing(10),
//   },
// }));

function Footer() {
  // const classes = useStyles();
  return (
    <StyledFooter position='relative'>
      {/*<footer className={classes.footer}>*/}
      <footer>
        <Typography variant="body2" color="inherit" align="center">
          Making our Parent Company
          {' '}<Link color="inherit" href="https://www.isphoenixing.com/">
            Phoenixing
          </Link> Proud all{' '}
          {new Date().getFullYear()}

          {'.'}
        </Typography>
      </footer>
    </StyledFooter>
  );
}

export default Footer;
