import * as React from "react";
// import makeStyles from '@mui/styles/makeStyles';
import {createStore} from 'polotno/model/store';
// import {NumericInput, Navbar, Alignment} from '@blueprintjs/core';

import {Toolbar} from 'polotno/toolbar/toolbar';
import {ZoomButtons} from 'polotno/toolbar/zoom-buttons';
import {Workspace} from 'polotno/canvas/workspace';
import {PolotnoContainer, SidePanelWrap, WorkspaceWrap} from "polotno";
import {
    SidePanel,
    DEFAULT_SECTIONS,
} from "polotno/side-panel";
import { unstable_setAnimationsEnabled, unstable_setTextOverflow } from 'polotno/config';
import ActionControls from "../components/EditorActionControls";
import {PageControls} from "../components/PageControl";
import {useEffect, useState} from "react";
import CustomSection from "../components/EditorCustomTemplatesPanel";

// import { Button, EditableText, Icon, Card } from '@blueprintjs/core';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import styled from "@emotion/styled";
import AppBar from "@mui/material/AppBar";

// const useStyles = makeStyles((theme) => ({
//     root: {
//     flexGrow: 1,
//   }
// }));

const EditorWrapper = styled('div')(({theme}) => ({
    padding: '-20px -24px',
    margin: '40px -24px'
}));


const store = createStore(
    {
        key: process.env.REACT_APP_POLOTNO_DEV,
        showCredit: false,
    }
);

const page = store.addPage();

const DemoPage = () => {
    const [templateName, setTemplateName] = useState('');
    const [hasError, setHasError] = useState(false);
    const [templateSaved, setTemplateSaved] = useState(false);

    // const classes = useStyles();
    useEffect(() => {
        unstable_setAnimationsEnabled(true);
        // default, change height of the text object when it overflow defined with/height
        unstable_setTextOverflow('resize');

        // add ... at the end of the text when it overflow defined with/height
        unstable_setTextOverflow('ellipsis');

        // reduce font size to fit text in the defined width/height
        // note, it will not increase font size back when there is more space
        unstable_setTextOverflow('change-font-size');
    }, []);

    const handleError = (error) => {
        setHasError(error);
    };


    return (
        <EditorWrapper>
            <PolotnoContainer className="polotno-app-container" style={{width: '100vw', height: '86vh'}}>
                <SidePanelWrap>
                    <SidePanel store={store} sections={[CustomSection, ...DEFAULT_SECTIONS]} defaultSection="custom"
                               templateSaved={templateSaved} setTemplateSaved={setTemplateSaved}/>
                </SidePanelWrap>
                <WorkspaceWrap>
                    <Toolbar
                        downloadButtonEnabled

                        store={store}
                        components={{
                            ActionControls: (props) => <ActionControls {...props} handleError={setHasError}
                                                                       style={{display: 'flex', flexDirection: 'row'}}
                                                                       hasError={hasError} templateName={templateName}
                                                                       templateSaved={templateSaved}
                                                                       setTemplateSaved={setTemplateSaved}/>
                        }}/>
                    <Workspace
                        store={store}
                        components={{
                            PageControls: (props) => <PageControls {...props} handleError={handleError}
                                                                   templateNmae={templateName}
                                                                   hasError={hasError} setTemplateName={setTemplateName}
                                                                   templateName={templateName}/>
                        }}/>

                    <ZoomButtons store={store}/>
                </WorkspaceWrap>
            </PolotnoContainer>
        </EditorWrapper>

    )
};

export default DemoPage