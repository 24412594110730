// src/utils/logger.js

// You can replace this with your preferred logging service (e.g., Sentry, LogRocket)
const logError = (message, error) => {
  console.error(message, error);
  
  // If you're using a service like Sentry, you might do something like:
  // Sentry.captureException(error);
  
  // You could also send the error to your own API endpoint
  // fetch('/api/log', {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({ message, error }),
  // });
};

const logInfo = (message, data) => {
  console.log(message, data);
  // Similar to logError, you could send this to a logging service
};

const logWarning = (message, data) => {
  console.warn(message, data);
  // Similar to logError, you could send this to a logging service
};

export { logError, logInfo, logWarning };