import React, {useState, useEffect} from 'react';
import {CardActions} from '@mui/material';
import {loadStripe} from '@stripe/stripe-js';
import {getPricingPlans} from "../api";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";

const MessageWrapper = styled('div')(({theme}) => ({
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.grey["80"],
    color: theme.palette.grey["100"],
    display: 'flex',
    borderRadius: theme.spacing(2),
    // height: '80vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));
const PricingPage = () => {
    const [pricingPlans, setPricingPlans] = useState([]);
    const tiers = [
        {
            title: 'Basic',
            price: '10',
            features: ['Feature 1', 'Feature 2'],
            buttonText: 'Get Started',
            buttonVariant: 'outlined',
        },
        // ... more tiers here
    ];

    useEffect(() => {
        const fetchData = async () => {
            const plans = await getPricingPlans();
            setPricingPlans(plans);
        };

        fetchData();
    }, []);

    return (
        <>
            <Box sx={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                <Typography align="center" variant="h2">
                    Pricing that meets your needs
                </Typography>
                <Typography align="center" variant="h4">
                    Choose a plan that fits your needs and start simplifying your social media management today. No
                    hidden fees. No hassle.
                </Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                {pricingPlans.sort((a, b) => a.price - b.price).slice(1).map((tier) => (
                    <Box key={tier.name} sx={{maxWidth: 300, m: 2}}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {tier.name}
                                </Typography>
                                <Typography variant="h6" component="div">
                                    ${tier.price}/mo
                                </Typography>

                                <Typography variant="body1">
                                    {tier.description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant={tier.buttonVariant}>
                                    {tier.buttonText}
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                ))}
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                <MessageWrapper>
                    <Typography align="center" variant="h4">
                        Join Solo Social
                    </Typography>
                    <Typography align="center" variant="h5">
                        We help you simplify social media so you can focus on all the other parts of your bussiness
                    </Typography>
                </MessageWrapper>

            </Box>
        </>
    );
};

export default PricingPage;
