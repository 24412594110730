import React, {useState} from 'react';
import {Stepper, Step, StepLabel, CardActions, useMediaQuery} from '@mui/material';
import Button from "@mui/material/Button";
import OnboardingStep3 from "../components/OnboardingStep3";
import OnboardingStep2 from "../components/OnboardingStep2";
import OnboardingStep1 from "../components/OnboardingStep1";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import {useStatus} from "../providers/MsgStatusProvider";
import {useAuth} from "../providers/AuthProvider";
import {useTheme} from "@mui/system";

export default function OnboardingFlowPage() {
    const [formData, setFormData] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Step 1', 'Step 2', 'Step 3'];
    const auth = useAuth();
    const {setStatusType, setStatusMessage, setIsAlertOpen} = useStatus();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    console.log(theme.breakpoints.down('md'))


    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        if (activeStep === 0) {
            console.log('set up account', formData);
            const url = process.env.REACT_APP_API_BASE_URL + 'user/onboarding/step/1';
            // fetch(url, {
            //     method: 'post',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': `Token ${auth.token}`
            //     },
            //     body: JSON.stringify(formData   )
            // })
            //     .then((response) => response.json())
            //     .then((data) => {
            //         if (data.status) {
            //             console.log(data)
            //             setActiveStep(prevActiveStep => prevActiveStep + 1);
            //         } else {
            //             setIsAlertOpen(true);
            //             setStatusMessage(data.message || data.detail);
            //             setStatusType('error')
            //         }
            //     })
            //     .catch((error) => {
            //         console.log(error)
            //         setIsAlertOpen(true);
            //         setStatusMessage('error.detail');
            //         setStatusType('error')
            //     });
        }
        if (activeStep === 1) {
            console.log({
                "account_categories": formData.account_categories
            })
            console.log('token', auth.token)
            console.log('set how they found us');
            const url = process.env.REACT_APP_API_BASE_URL + 'user/onboarding/step/2';
            const userContentTypeForm = {}

            // fetch(url, {
            //     method: 'post',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': `Token ${auth.token}`
            //     },
            //     body: JSON.stringify({
            //         "account_categories": formData.account_categories
            //     })
            // })
            //     .then((response) => response.json())
            //     .then((data) => {
            //         if (data.status) {
            //             alert('done')
            //             setIsAlertOpen(false);
            //             // setStatusMessage(data.message);
            //             // setStatusType('success')
            //             setActiveStep(prevActiveStep => prevActiveStep + 1);
            //         } else {
            //             setIsAlertOpen(true);
            //             setStatusMessage(data.message);
            //             setStatusType('error')
            //         }
            //     })
            //     .catch((error) => {
            //         console.log(error)
            //         setIsAlertOpen(true);
            //         setStatusMessage('error.detail');
            //         setStatusType('error')
            //     });
        }

    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    const handleSubmit = () => {
        fetch('/api/onboarding', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                // handle success
            })
            .catch(error => {
                // handle error
            });
    };

    return (
        <Grid container display="flex" justifyContent="start" alignItems="center">
            <Card variant="outlined" sx={{minWidth: isMobile ? '100%' : 700}}>
                <CardContent>
                    {/*<Stepper activeStep={activeStep} orientation={isMobile ? "vertical" : "horizontal"}>*/}
                    <Stepper activeStep={activeStep} orientation={"horizontal"}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === 0 && <OnboardingStep1 setFormData={setFormData}/>}
                    {activeStep === 1 && <OnboardingStep2 setFormData={setFormData}/>}
                    {activeStep === 2 && <OnboardingStep3 setFormData={setFormData}/>}
                </CardContent>
                <CardActions>
                    {activeStep === steps.length ? (
                        <Button onClick={handleSubmit}>Submit</Button>
                    ) : (
                        <Grid container display="flex" justifyContent="space-between" sx={{pl: 10, pr: 10}}>
                            <Button disabled={activeStep === 0} onClick={handleBack}
                                    sx={{fontSize: isMobile ? '16px' : '14px'}}>
                                Back
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleNext}
                                    sx={{fontSize: isMobile ? '16px' : '14px'}}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Grid>
                    )}
                </CardActions>
            </Card>
        </Grid>
    );
}