import React, {useState} from 'react';
import {Button, TextField, FormControl, InputLabel, Select, MenuItem, Box} from '@mui/material';
// import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import {projectStyles} from "../styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert/Alert";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Link from "@mui/material/Link";
import styled from "@emotion/styled";


const Root = styled(Grid)(({ theme }) => ({
  // display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'center',
  margin: theme.spacing(3),
}));

const NoPadding = styled('div')({
  marginTop: '-64px',
  paddingTop: '-16px',
  marginBottom: '0px',
  height: '80vh',
});

const ContainerStyled = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
});

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
}));

const SubmitButton = styled('button')(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

function Register() {
    // const classes = useStyles();
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        conncetion: ''
    });

    const handleChange = event => {
        const {name, value} = event.target;
        setFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleCheckboxChange = event => {
        setAgreeToTerms(event.target.checked);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = process.env.REACT_APP_API_BASE_URL + 'user/register/';
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "username": username,
                    "first_name": firstName,
                    "last_name": lastName,
                    "email": username,
                    "password": password
                })
            });

            const data = await response.json();

            if (response.ok) {
                // handle successful registration
                console.log('User registered successfully:', data);
                localStorage.setItem('token', data.token);
                setErrorMessage([])
                setIsAlertOpen(true);
                setStatusMessage(data.message);
                setStatusType('success')
            } else {
                // handle registration error
                console.error('Registration error:', data);
                setErrorMessage(event.target.value)
            }
        } catch (error) {
            // handle network error
            console.error('Network error:', error);
            setErrorMessage(event.target.value)
        }
    };

    return (
        <Root container>
            {/*<Grid container className={classes.nopadding}>*/}
            <Grid item xs={12} sm={4}>
                {/* Left side */}
                <ContainerStyled>
                    {/*<div className={classes.container}>*/}
                    <FormControl variant="standard">
                        <Typography variant="h3" align="center">
                            Join Solo Social
                        </Typography>
                        <Form onSubmit={handleSubmit}>
                            {/*<form className={classes.form} onSubmit={handleSubmit}>*/}
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <TextFieldStyled
                                        label="First Name"
                                        variant="outlined"
                                        // className={classes.textField}
                                        name="name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextFieldStyled
                                        label="Last Name"
                                        variant="outlined"
                                        // className={classes.textField}
                                        name="name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl>
                                    <TextFieldStyled
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                        // className={classes.textField}
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldStyled
                                        label="Password"
                                        variant="outlined"
                                        // className={classes.textField}
                                        type="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <InputLabel id="connection-label">How did you find us?</InputLabel>
                                        <Select
                                            labelId="connection-label"
                                            id="connection"
                                            variant="outlined"
                                            // className={classes.textField}
                                            name="connection"
                                            value={formData.connection}
                                            onChange={handleChange}
                                            required
                                        >
                                            <MenuItem value="Twitter">Twitter</MenuItem>
                                            <MenuItem value="Facebook">Facebook</MenuItem>
                                            <MenuItem value="Instagram">Instagram</MenuItem>
                                            <MenuItem value="Blue Sky">Blue Sky</MenuItem>
                                            <MenuItem value="Email">Email</MenuItem>
                                            <MenuItem value="Friend">Friend</MenuItem>
                                            <MenuItem value="Search Engine">Search Engine</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={agreeToTerms}
                                        onChange={handleCheckboxChange}
                                        name="agreeToTerms"
                                        color="primary"
                                    />
                                }
                                label={
                                    <>
                                        By creating an account you I agree to the{" "}
                                        <Link href="/terms-and-conditions">
                                            Terms and Conditions
                                        </Link>{" "}
                                        and{" "}
                                        <Link href="/privacy-policy">
                                            Privacy Policy
                                        </Link>
                                    </>
                                }
                            />
                            <Button
                                type="submit"
                                fullWidth={true}
                                variant="contained"
                                color="primary"
                                // className={classes.submit}>
                            >
                                Register
                            </Button>
                        </Form>
                    </FormControl>
                </ContainerStyled>
            </Grid>
            <Grid item xs={12} sm={8}>
                {/* Right side */}
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="115%"
                >
                    <img src="your-image-url-here" alt="Your description here"/>
                </Box>
            </Grid>
        </Root>
    )
}

export default Register;

