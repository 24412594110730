import React, {useEffect, useState} from "react";
import {SectionTab} from "polotno/side-panel";
import {observer} from "mobx-react-lite";
import ImageListItem from "@mui/material/ImageListItem";
import ImageList from "@mui/material/ImageList";

function useCustomTemplates() {
  const [templates, setTemplates] = useState([]);
  const url = process.env.REACT_APP_API_BASE_URL + 'templates/';
  useEffect(() => {
    fetch(url,
         {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
            },
        }
    )
      .then(response => response.json())
      .then(data => setTemplates(data))
      .catch(error => console.error(error));
  }, []);

  return templates;
}

const CustomSection = {
  name: 'Branded',
  Tab: (props) => (
    <SectionTab name="Branded" {...props}>
      Icon
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    const templates = useCustomTemplates();

    if (templates.length > 0) {
      return (
        <div style={{
            height: '100%',
            overflowX: 'scroll'
        }}>
          <p>Here's a list of your custom templates:</p>
            <ImageList  cols={2} rowHeight={164}>
              {templates.map(template => (
                <ImageListItem onClick={()=>{ store.loadJSON(template.template) }} key={template.id}>
                  <img
                    src={`${template.preview_img}`}
                    alt={template.id}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
        </div>
      );
    } else {
      return (
        <div>
          <p>Sorry, you haven't saved any templates yet.</p>
        </div>
      );
    }
  }),
};

export default CustomSection;
