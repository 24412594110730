import React from 'react';
import {TextField, Button, Box, Checkbox, FormControlLabel, useMediaQuery} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/system";

export default function OnboardingStep2({setFormData}) {
    const [selectedValues, setSelectedValues] = React.useState({
        content_creator: false,
        small_business: false,
        events: false,
        other: false
    });
    const [otherValue, setOtherValue] = React.useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleCheckboxChange = (name) => {
        setSelectedValues((prevValues) => {
            const updatedValues = {...prevValues, [name]: !prevValues[name]};

            // Update formData directly based on checkbox state
            setFormData(prevFormData => {
                let updatedCategories = prevFormData.account_categories || [];

                if (updatedValues[name] && !updatedCategories.includes(name)) { // if it's now true
                    updatedCategories = [...updatedCategories, name];
                } else if (!updatedValues[name] && updatedCategories.includes(name)) { // if it's now false
                    updatedCategories = updatedCategories.filter(category => category !== name);
                }

                return {...prevFormData, account_categories: updatedCategories};
            });

            return updatedValues;
        });
    };

    const handleOtherChange = event => {
        setOtherValue(event.target.value);
    };

    React.useEffect(() => {
        if (selectedValues.other && otherValue.trim()) {
            setFormData(prevFormData => ({...prevFormData, other: otherValue}));
        }
    }, [otherValue, setFormData, selectedValues.other]);

    return (
        <div>
            <Typography variant="h6">
                What best describe how you'll use the platform?
            </Typography>
            <Box display="flex" flexDirection={isMobile ? "column": "row"} gap={2}>
                {['content_creator', 'small_business', 'events', 'other'].map((name) => (
                    <Button
                        key={name}
                        variant={selectedValues[name] ? "contained" : "outlined"}
                        color="primary"
                        startIcon={
                            name === 'content_creator' ? <PersonIcon/> :
                                name === 'small_business' ? <BusinessIcon/> :
                                    name === 'events' ? <EventIcon/> : null
                        }
                        onClick={() => handleCheckboxChange(name)}
                    >
                        {name.replace('_', ' ')}
                        <Checkbox
                            checked={selectedValues[name]}
                            onChange={() => handleCheckboxChange(name)}
                            name={name}
                            style={{display: 'none'}} // Hide the checkbox visually
                        />
                    </Button>
                ))}
                {selectedValues.other && (
                    <TextField
                        label="Specify Other"
                        variant="outlined"
                        value={otherValue}
                        onChange={handleOtherChange}
                    />
                )}
            </Box>
        </div>
    );
}
