import React, {useEffect} from 'react';
import {Grid} from '@mui/material';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {useParams} from "react-router-dom";
import {useStatus} from "../providers/MsgStatusProvider";
import {useNavigate} from "react-router";
import {useAuth} from "../providers/AuthProvider";

const ConfirmEmailPage = () => {
    const {token} = useParams()
    const navigate = useNavigate();
    const {setToken} = useAuth();
    const {setStatusType, setStatusMessage, setIsAlertOpen} = useStatus();

    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + `user/confirm-email/${token}/`;
        fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status) {
                    setIsAlertOpen(true);
                    setStatusMessage(data.message);
                    setStatusType('success');
                    localStorage.setItem('token', data.token);
                    setToken(data.token);
                    navigate('/onboarding');

                } else {
                    setIsAlertOpen(true);
                    setStatusMessage(data.message);
                    setStatusType('error')
                }
            })
            .catch((error) => {
                console.error(error.message);
                setIsAlertOpen(true);
                setStatusMessage(error.message);
                setStatusType('error');
            });
    }, []);


    return (
        <Grid container style={{minHeight: '73vh'}}>
            <Grid item sm={12}>
                <Typography align="center" component="h1" variant="h4">
                    We've confirmed you are you 🥳
                </Typography>
                <Typography align="center" component="p" variant="body1">
                    You will be redirected to the next step in a few seconds.
                </Typography>
                <Typography align="center" component="p" variant="body2">
                    Did the internet stop interneting? It's cool just <Link to='/onboarding/pricing'> click this link to
                    go to the next step</Link>.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ConfirmEmailPage;
