import React, { useState, useEffect } from 'react';
import { ImageListItem, ImageListItemBar } from '@mui/material';
import ImageList from "@mui/material/ImageList";

const TemplateDisplayGrid = ({ onSelect }) => {
  const [images, setImages] = useState([]);
  const url = process.env.REACT_APP_API_BASE_URL + 'templates/';
  useEffect(() => {
    fetch(url,
         {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
            },
        }
    )
      .then(response => response.json())
      .then(data => setImages(data))
      .catch(error => console.error(error));
  }, []);

  return (
    <ImageList cols={4}>
      {images.map((image) => (
        <ImageListItem key={image.id} onClick={() => onSelect(image)}>
          <img src={image.preview_img} alt={image.id} />
          <ImageListItemBar title={image.name} />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default TemplateDisplayGrid;