import React, {useState} from 'react';
import {Card, CardMedia, CardContent, Typography, Button} from '@mui/material';

const TemplateGenerateForm = ({image, onUpdate}) => {
    const [jsonData, setJsonData] = useState(image.template);
    const [previewNewImage, setPreviewNewImage] = useState('');

    // console.log(jsonData, 'jsonData')

    const handleUpdate = () => {
        fetch(`https://api.polotno.com/api/render?KEY=${process.env.REACT_APP_POLOTNO_DEV}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                // polotno json from store.toJSON()
                design: jsonData,
                // optional output format for export
                outputFormat: 'url',
                // optional export options for store.toDataURL() method
                exportOptions: {},
            }),
        }).then((response) => response.json()
            .then((data) => {
                if (data) {
                    setPreviewNewImage(data.url);
                } else {
                    console.warn(data)
                    // setErrorMessage(data);
                }
            })
        )
    }

    // Create all of the inputs based on the template
    const childrenInputs = jsonData.pages[0].children.filter(child => child.styleEditable === true).map(child => {
        if (child.type === 'image') {
            return (
                <div key={child.id}>
                    <label htmlFor={`x_${child.id}`}>X:{child.name}</label>
                    <input
                        id={`x_${child.id}`}
                        type='url'
                        value={child.src}
                        onChange={event => {
                            const newChildren = [...jsonData.pages[0].children];
                            const index = newChildren.findIndex(c => c.id === child.id);
                            newChildren[index] = {...child, src: event.target.value};
                            setJsonData({...jsonData, pages: [{...jsonData.pages[0], children: newChildren}]});
                        }}
                    />
                    <img src={child.src}
                         height='200'
                         width='200'
                         alt="New Image"/>
                </div>
            )
        } else if (child.type === 'text') {
            return (
                <div key={child.id}>
                    <label htmlFor={`x_${child.id}`}>X:{child.name}</label>
                    <input
                        id={`x_${child.id}`}
                        type='text'
                        value={child.text}
                        onChange={event => {
                            const newChildren = [...jsonData.pages[0].children];
                            const index = newChildren.findIndex(c => c.id === child.id);
                            newChildren[index] = {...child, text: event.target.value};
                            setJsonData({...jsonData, pages: [{...jsonData.pages[0], children: newChildren}]});
                        }}
                    />
                </div>
            )
        }
    });

    const saveGenImage = () => {
        const url = process.env.REACT_APP_API_BASE_URL + 'templates/create/gen/img';
        // if (previewNewImage) {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
                },
                body: JSON.stringify({
                    parent_template: image.id,
                    json_template: jsonData,
                    preview_img: previewNewImage,
                })
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        // Store the JWT in a cookie or local storage
                        // Redirect the user to the home page
                        console.log(data)
                        alert('saved');
                    } else {
                        console.log(data)
                        // setErrorMessage(data['non_field_errors']);
                    }
                });
        // } else {
        //     alert('Please create generate a new image.')
        // }
    }

    return (
        <Card>
            <CardMedia component="img" image={image.url} title={image.title}/>
            <CardContent>
                <Typography variant="h5">{image.title}</Typography>
                {childrenInputs}
                <Button variant="contained" color="primary" onClick={handleUpdate}>
                    Update
                </Button>
                {previewNewImage ?
                    <>
                        <img src={previewNewImage} height="300px" width="300px" alt="Preview New Image"/>
                        <Button variant="contained"
                                color='primary'
                                onClick={saveGenImage}>Save Image</Button>
                    </>
                    : null
                }
            </CardContent>
        </Card>
    );
};

export default TemplateGenerateForm;
