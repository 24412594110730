import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router";
import {useStatus} from "../providers/MsgStatusProvider";

function SocialConnectionTwitterPage() {
    const navigate = useNavigate();
    const [twitterDetailsSet, setTwitterDetailsSet] = useState()
    const {setStatusMessage, setIsAlertOpen, setStatusType} = useStatus();
    const nav = useNavigate()

    const searchParams = new URLSearchParams(location.search);
    const oauthToken = searchParams.get('oauth_token');
    const oauthVerifier = searchParams.get('oauth_verifier');

    useEffect(() => {

        if (oauthToken && oauthVerifier) {
            // Do something with oauthToken and oauthVerifier
            const url = process.env.REACT_APP_API_BASE_URL + 'connect/twitter/callback/';
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
                },
                body: JSON.stringify({
                    oauth_verifier: oauthVerifier,
                    request_token: oauthToken
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.message === 'Twitter account connected successfully') {
                        setTwitterDetailsSet(true);
                        nav('/profile')
                        setStatusMessage("You've successfully connected to Twitter")
                        setIsAlertOpen(true)
                        setStatusType("success")

                    } else {
                        console.error(data);
                    }
                });
        }
    }, [location]);

    return (
        <>
            <Typography variant="h1">
                You're Connected to Twitter.
            </Typography>
            <Typography variant="h4">
                You will be redirected shortly.
            </Typography>
        </>
    );
}

export default SocialConnectionTwitterPage;
