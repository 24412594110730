import React from 'react';
import Button from '@mui/material/Button';

const LinkedInConnectButton = () => {

  const handleConnect = () => {
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_URL}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
    window.location.href = url
  };

  return (
   <>
        <Button variant="contained" color="primary" onClick={handleConnect}>
            Connect to LinkedIn
          </Button>
    </>
  );
};

export default LinkedInConnectButton;
