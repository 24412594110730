import React, { useState } from 'react';
import TemplateDisplayGrid from "../components/TemplateDisplayGrid";
import TemplateGenerateForm from "../components/TemplateGenerateForm";

const TemplateGeneratorPage = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSelect = (image) => {
    setSelectedImage(image);
  };

  const handleUpdate = (updatedImage) => {
    setSelectedImage(updatedImage);
  };

  return (
    <>
      {selectedImage ? (
        <TemplateGenerateForm image={selectedImage} onUpdate={handleUpdate} />
      ) : (
        <TemplateDisplayGrid onSelect={handleSelect} />
      )}
    </>
  );
};

export default TemplateGeneratorPage;
