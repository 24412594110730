import React from 'react';
import PricingPlansPage from "../pages/PricingPlansPage";
import Typography from "@mui/material/Typography";

export default function OnboardingStep3({setFormData}) {
    const handleChange = event => {
        const {name, value} = event.target;
        setFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    return (
        <>
            <Typography variant="h2" >
                Select a Plan
            </Typography>
            <Typography variant="h6" >
                Select a Plan
            </Typography>
            <PricingPlansPage/>
        </>
    );
}
