import * as React from 'react';
import {createRoot} from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import App from './App';
import {AuthProvider} from "./providers/AuthProvider";
import {StatusProvider} from "./providers/MsgStatusProvider";
import theme from './theme';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AuthProvider>
                <StatusProvider>
                    <App />
                </StatusProvider>
            </AuthProvider>
        </ThemeProvider>
    </StyledEngineProvider>,
);
