import React, { useEffect, useState } from "react";
import {useAuth} from "../providers/AuthProvider";

const PricingPlansPage = () => {
  const auth = useAuth();
  const user = auth.user[0];
  const [plans, setPlans] = useState([]);

// console.log(auth);
    // useEffect(() => {
    //   const pricingTableDiv = document.getElementById('stripe-pricing-table');
    //   console.log(auth);
    //   if (!auth.isLoading){
    //     pricingTableDiv.innerHTML = `<stripe-pricing-table customer-email="${user.email}" pricing-table-id="prctbl_1N0yNXIX1FUp6F9lszbaITbE" publishable-key="pk_test_0QsdLw6GrTeQEoVSQLLz8Q3t00dlDUkNwF"></stripe-pricing-table>`;
    //   } else {
    //       alert('please login')
    //   }
    // }, [user]);


  // useEffect(() => {
  //   fetch("http://localhost:8000/user/pricing-plans/")
  //     .then((response) => {
  //       console.log(response);
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch pricing plans");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => setPlans(data))
  //     .catch((error) => console.error(error));
  // }, []);
  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  return (

  <stripe-pricing-table customer-email={user?.email || ''} pricing-table-id="prctbl_1N0yNXIX1FUp6F9lszbaITbE" publishable-key="pk_test_0QsdLw6GrTeQEoVSQLLz8Q3t00dlDUkNwF"></stripe-pricing-table>
  );
};

export default PricingPlansPage;
