import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import InstagramConnectButton from "../components/InstagramConnectButton";
import TwitterConnectButton from "../components/TwitterConnectButton";

function SocialConnectionPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
      event.preventDefault();
      const url = process.env.REACT_APP_API_BASE_URL + 'connect/instagram/account/';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRFToken': 'YOUR_CSRF_TOKEN_HERE', // Replace with your actual CSRF token
        },
        body: new URLSearchParams({
          username: username,
          password: password,
        }).toString(),
      });
      if (response.redirected) {
        window.location.href = response.url;
      } else {
        console.error('Instagram login failed.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
        <TwitterConnectButton />
      <TextField
        label="Username"
        variant="outlined"
        fullWidth
        value={username}
        onChange={handleUsernameChange}
      />
      <TextField
        label="Password"
        variant="outlined"
        fullWidth
        type="password"
        value={password}
        onChange={handlePasswordChange}
      />
      <Button variant="contained" color="primary" type="submit">
        Login
      </Button>
    </form>
  );
}

export default SocialConnectionPage;
