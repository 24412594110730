import React from 'react';
import {Grid, InputLabel, OutlinedInput, Select, MenuItem} from '@mui/material';
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

export default function OnboardingStep1({setFormData}) {
    const handleChange = event => {
        const {name, value} = event.target;
        setFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    return (
        <div>
            <Typography variant="h4" align="center" space>
                Complete your account setup!
            </Typography>
            <Typography variant="body1" align="center" space>
                We've confirmed your account now let's
            </Typography>
            <form>
                <Grid display="flex" justifyContent="center" direction="column" alignItems="center">
                    <FormControl sx={{width: '100%', marginBottom: 2}}>
                        <InputLabel htmlFor="my-input">First Name</InputLabel>
                        <OutlinedInput label="First Name" name="first_name" id="first_name" onChange={handleChange}
                                       aria-describedby="first-name"/>
                    </FormControl>
                    <FormControl sx={{width: '100%', marginBottom: 2}}>
                        <InputLabel htmlFor="last-input">Last Name</InputLabel>
                        <OutlinedInput label="LastName" name="last_name" onChange={handleChange}
                                       aria-describedby="last-name"/>
                    </FormControl>
                    <FormControl sx={{width: '100%', marginBottom: 2}}>
                        {/*<InputLabel htmlFor="passowrd">Password</InputL  abel>*/}
                        <TextField variant="outlined" type="password" onChange={handleChange} name="password" label="Password"  id="passowrd" aria-describedby="password-helper-text"/>
                    </FormControl>
                        <FormControl sx={{width: '100%', marginBottom: 2}}>
                            <InputLabel id="connection-label">How did you find us?</InputLabel>
                            <Select
                                labelId="connection-label"
                                id="connection"
                                variant="outlined"
                                name="connection"
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="Twitter">Twitter</MenuItem>
                                <MenuItem value="Facebook">Facebook</MenuItem>
                                <MenuItem value="Instagram">Instagram</MenuItem>
                                <MenuItem value="Blue Sky">Blue Sky</MenuItem>
                                <MenuItem value="Email">Email</MenuItem>
                                <MenuItem value="Friend">Friend</MenuItem>
                                <MenuItem value="Search Engine">Search Engine</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                </Grid>
            </form>
        </div>
    );
}
