import * as React from 'react';
import styled from '@emotion/styled';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Partnerships from "../components/Partnerships";
import {useState} from "react";
import {useStatus} from "../providers/MsgStatusProvider";
import {useNavigate} from "react-router";
import theme from "../theme";

const HeroSubTitle = styled(Typography)(({theme}) => ({
    color: theme.palette.grey["300"],
    '@media (max-width: 768px)': {
        fontSize: '1.2rem',
    },
}));

const MessageWrapper = styled('div')(({theme}) => ({
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.grey["80"],
    color: theme.palette.grey["100"],
    display: 'flex',
    borderRadius: theme.spacing(2),
    // height: '80vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const HomePageSection = styled(Container)(({theme}) => ({
    paddingToo: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
}));
const StyledHero = styled(Grid)(({theme}) => ({
    backgroundColor: theme.palette.secondary.main,
    // backgroundColor: theme.palette.grey["200"],
    // backgroundColor: '#9EDCAE',
    // padding: '34px 24px',
    paddingRight: theme.spacing(7),
    paddingLeft: theme.spacing(7),
    borderRadius: theme.spacing(2),
    height: '75vh',
}));
const BlackCard = styled(Card)(({theme}) => ({
    // backgroundColor: theme.palette.common.black,
    backgroundColor: theme.palette.grey['300'],
    color: theme.palette.common.white,
    textAlign: 'center',
    height: '500px'
}));
const PrimaryCard = styled(Card)(({theme}) => ({
    // backgroundColor: theme.palette.common.black,
    // backgroundColor: "#dcddde",
    // backgroundColor: theme.palette.grey['300'],
    marginBottom: theme.spacing(2),
    // backgroundColor: theme.palette.grey['80'],
    backgroundColor: theme.palette.grey['80'],
    color: theme.palette.common.white,
    textAlign: 'center',
    width: '75%',
    padding: theme.spacing(4),
}));
const StyledHoverSection = styled(Grid)(({theme}) => ({
    marginTop: '-40px',
    '@media (max-width: 768px)': {
        marginTop: '-126px',
    },
}));
const MainTitle = styled.h1(({theme}) => ({
    fontSize: '5rem',
    marginTop: '0px',
    position: 'relative',
    '@media (max-width: 768px)': {
        fontSize: '3rem',
    },
    '&:after': {
        content: '""',
        position: 'absolute',
        bottom: '-10px',
        left: '0',
        width: '100px',
        height: '4px',
        backgroundColor: theme.palette.common.black,
    }
}));

const StyledCard = styled(Card)`
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const LoginButton = styled(Button)`
  background-color: transparent;
  border: 2px solid black;
  color: black;
  margin-right: 10px; // to create space between this button and the next
  &:hover {
    background-color: #f0f0f0;
  }
`;

const GetStartedButton = styled(Button)`
  background-color: green; // you can adjust this to the exact shade you want
  color: white;

  &:hover {
    background-color: darkgreen; // darker shade on hover for effect
  }
`;

export default function LandingPage() {
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState([]);
    const {setStatusType, setStatusMessage, setIsAlertOpen} = useStatus();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = process.env.REACT_APP_API_BASE_URL + 'user/register/email';
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "email": username,
                })
            });

            const data = await response.json();

            if (response.ok) {
                // handle successful registration
                localStorage.setItem('token', data.token);
                setErrorMessage([])
                setIsAlertOpen(true);
                setStatusMessage(data.message);
                setStatusType('success')
                navigate('/check-email');
            } else {
                // handle registration error
                setErrorMessage(event.target.value)
            }
        } catch (error) {
            // handle network error
            setErrorMessage(event.target.value)
        }
    };


    return (
        <Container style={{marginTop: '20px'}}>
            <StyledHero container alignItems="center" justifyContent="center" display="flex">
                <Grid item md={12}>
                    <MainTitle>Solo Social</MainTitle>
                    <HeroSubTitle variant="h3">
                        We help Craft, business owners, event organizers &amp; content creators craft,
                        schedule, &amp; amplify online content.
                    </HeroSubTitle>
                </Grid>
            </StyledHero>
            <StyledHoverSection container display="flex" justifyContent="center">
                <PrimaryCard>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit}>
                            <Grid display="flex" justifyContent="center" alignItems="center" direction="column">
                                <Typography color="black" variant="h5" style={{width: '50%'}}>
                                    Join Today
                                </Typography>
                                <Grid container display="flex" justifyContent="center" mt={2}>
                                    <Grid item xs={9} md={5}>
                                        <TextField
                                            required
                                            style={{marginBottom: 0}}
                                            id="email"
                                            name="email"
                                            label="Email"
                                            onChange={(event) => setUsername(event.target.value)}
                                            fullWidth
                                            autoComplete="email"
                                        />
                                    </Grid>

                                    <Grid item xs={3} md={2} style={{display: "flex"}}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                        >
                                            Sign Up
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="small" color="black">
                                            By signing up you agree to the terms and conditions & privacy policy. We may
                                            send you some casual updates, but promise not to overdo it!
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </PrimaryCard>
            </StyledHoverSection>

            <HomePageSection maxWidth="md" component="section">
                <div style={{marginBottom: '32px'}}>
                    <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
                        Save Time
                    </Typography>
                    <Typography variant="h2" align="center" color="textPrimary" gutterBottom>
                        Stay consistent. Stay on brand.
                    </Typography>
                    <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                        Managing consistent, engaging online content is a challenge. With numerous platforms and
                        ever-evolving audience preferences, it's tough to keep up.
                    </Typography>
                </div>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={4}>
                        <BlackCard style={{'height': "100%"}}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Create Templates
                                </Typography>
                                <Typography variant="body1" component="p">
                                    Use our web based editing tool to create templates that you can use to
                                    generate new images to post online
                                </Typography>
                            </CardContent>
                        </BlackCard>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <BlackCard style={{height: '100%'}}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Create Dynamic Content
                                </Typography>
                                <Typography variant="body1" component="p">
                                    Write out your post for each platform. Attach images to the post by
                                    selecting a
                                    template and updating a form and your new image is generated in mins.
                                </Typography>
                            </CardContent>
                        </BlackCard>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <BlackCard style={{height: '100%'}}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Post & Go
                                </Typography>
                                <Typography variant="body1" component="p">
                                    Managing multiple social platforms? Select all the platforms you want to
                                    post to
                                    and
                                    schedule at a later time or post right away.
                                </Typography>
                            </CardContent>
                        </BlackCard>
                    </Grid>
                </Grid>
            </HomePageSection>
            <Container maxWidth="md" component="section">
                <MessageWrapper styles={{width: "100vw"}}>
                    <Typography variant="h2" align="center" color="textPrimary" gutterBottom>
                        Finding time for effective online marketing often falls by the wayside.
                    </Typography>
                    <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
                        With Solo Social, marketing efficiency is no longer out of reach. Our platform streamlines
                        content creation, scheduling, and analysis, giving you time back to focus on your business.
                    </Typography>
                </MessageWrapper>
            </Container>
        </Container>
    )
}
