import React, {useEffect} from "react";
import Alert from '@mui/material/Alert';
import {useStatus} from "../providers/MsgStatusProvider";
import styled from '@emotion/styled';
import IconButton from "@mui/material/IconButton";

const StyledAlert = styled(Alert)`
  position: fixed;
  top: 8px;  // This is an approximation of theme.spacing(1). Adjust as needed.
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  width: 90%;
  max-width: 600px;
`;


const StatusAlert = () => {
  const { statusMessage, statusType, setStatusMessage, setIsAlertOpen, isAlertOpen } = useStatus();

  const handleClose = () => {
    setStatusMessage(''); // Clear the status message
    setIsAlertOpen(false);
  };
    useEffect(() => {
        if(isAlertOpen){
            setTimeout(()=>{
                setIsAlertOpen(false)
                setStatusMessage("")
            }, 5000)
        }
    }, [isAlertOpen]);

  return isAlertOpen && (
    <StyledAlert  severity={statusType}
           color={statusType}
    action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          x
        </IconButton>
      }>
      {statusMessage}
    </StyledAlert>
  );
};

export default StatusAlert;