import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Box, Button } from '@mui/material';
import {useShoppingCart} from "../providers/ShoppingCartProvider";


function SocialMediaPostDetailsPage(props) {
  const { id } = useParams();
  const [book, setBook] = useState({});
  const { addItem, removeItem, items } = useShoppingCart();
  const [isInCart, setIsInCart] = useState(false);
  const url = process.env.REACT_APP_API_BASE_URL + 'audio-books/'+id+'/';
  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setBook(data);
      });
  }, [id]);

  useEffect(() => {
    setIsInCart(items.some((item) => item.id === book.id));
  }, [items, book.id]);

  return (
    <div>
      <Box my={2}>
        <Breadcrumbs>
          <Link to="/audiobooks">Audio Books</Link>
          <p>{book.title}</p>
        </Breadcrumbs>
      </Box>
      <h1>{book.title}</h1>
      <p>by {book.author}</p>
      <p>{book.duration}</p>
      <p>{book.description}</p>
        {isInCart? (
            <Button onClick={() => removeItem(book)}>Remove from Cart</Button>
        ):(
            <Button onClick={() => addItem(book)}>Add to Cart</Button>
        )}
    </div>
  );
}

export default SocialMediaPostDetailsPage;
