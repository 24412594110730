import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const TwitterConfirmPin = ({ handlePinSubmit }) => {
  const [pin, setPin] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    handlePinSubmit(pin);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="pin"
        label="Enter Twitter PIN"
        name="pin"
        autoComplete="off"
        autoFocus
        value={pin}
        onChange={(event) => setPin(event.target.value)}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
      >
        Submit
      </Button>
    </form>
  );
};

export default TwitterConfirmPin;
