import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TwitterConfirmPin from "./TwitterConfirmPin";
import {useLocation} from "react-router";
import {useStatus} from "../providers/MsgStatusProvider";

const TwitterConnectButton = () => {
    const [showPinScreen, setShowPinScreen] = useState(false);
    const [twitterDetailsSet, setTwitterDetailsSet] = useState(false);
    const [requestToken, setRequestToken] = useState('');
    let currentUrl = new URL(window.location.href);
    const location = useLocation();
    const oauthToken = currentUrl.searchParams.get('oauth_token');
    const oauthVerifier = currentUrl.searchParams.get('oauth_verifier');
    const {setStatusType, setStatusMessage, setIsAlertOpen} = useStatus();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const oauthToken = searchParams.get('oauth_token');
        const oauthVerifier = searchParams.get('oauth_verifier');

        if (oauthToken && oauthVerifier && !twitterDetailsSet) {
            // Do something with oauthToken and oauthVerifier
            const url = process.env.REACT_APP_API_BASE_URL + 'connect/twitter/callback/';
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
                },
                body: JSON.stringify({
                    oauth_verifier: oauthVerifier,
                    request_token: oauthToken
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.message == 'Twitter account connected successfully') {
                        setTwitterDetailsSet(true);
                        window.location.href = 'https://dev.onsolosocial.com/connect/socials';
                        alert("we in")
                    } else {
                        console.error(data);
                    }
                });
        }
    }, [location]);

    const handleConnect = () => {
        const url = process.env.REACT_APP_API_BASE_URL + 'connect/twitter/authorize/';
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setRequestToken(data.request_token);
                setShowPinScreen(true);
                window.location.href = data.redirect_url;
            })
            .catch((error) => {
                console.error(error.message);
                setIsAlertOpen(true);
                 setStatusMessage(error.message);
                 setStatusType('error');
            });
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleConnect}>
                Connect to Twitter
            </Button>
        </>
    );
};

export default TwitterConnectButton;
