import React from "react";
// import makeStyles from '@mui/styles/makeStyles';
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import {useAuth} from "../providers/AuthProvider";
import Paper from "@mui/material/Paper";
import TwitterConnectButton from "./TwitterConnectButton";
import Button from "@mui/material/Button";
import LinkedInConnectButton from "./LinkedInConnectButton";
import InstagramConnectButton from "./InstagramConnectButton";

// const useStyles = makeStyles((theme) => ({
//     callout: {
//         backgroundColor: theme.palette.grey["50"],
//         padding: theme.spacing(1),
//         marginBottom: theme.spacing(2)
//     }
//
// }));

const SocialAccountsDisplay = ({filtered_account}) => {
    // const classes = useStyles();
    const auth = useAuth();

    const twitterAccounts = auth.user[0]?.userprofile.social_accounts.filter(account => account.platform === filtered_account);

    const disconnectAccount = (id) => {
        const url = process.env.REACT_APP_API_BASE_URL + `connect/remove`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.token}` // include authentication token in headers
            },
            body: JSON.stringify({
                social_account_id: id,
            })
        }).then((response) => response.json()
            .then((data) => {
                if (data) {
                    console.log(data, 'success')
                } else {
                    console.warn(data)
                    // setErrorMessage(data);
                }
            })
        )
    };

    if (auth.user[0]?.userprofile.social_accounts.length > 0) {
        return (
            <div>
                {auth.user[0]?.userprofile.social_accounts.length >= 3 && filtered_account === 'twitter' &&
                    <Paper elevation={2} color='primary' variant="outlined">
                        You've connected to the max amount of account for your subscription. To connect to more please
                        disconnect from one of your accounts now.
                    </Paper>
                }
                {twitterAccounts.map(account => (
                    <div key={account.user_id}>
                        <ListItemIcon>
                            <Avatar alt={account.username} src={account.account_img}/>
                        </ListItemIcon>
                        {account.username} {' '}
                        <Button variant="text"
                                size="small"
                                onClick={() => {
                                    disconnectAccount(account.id)
                                }}
                                color="primary">
                            Disconnect
                        </Button>
                    </div>
                ))}
                {twitterAccounts.length < 3 && filtered_account === 'twitter' &&
                    <TwitterConnectButton/>
                }
                {twitterAccounts.length < 3 && filtered_account === 'linkedin' &&
                    <LinkedInConnectButton/>
                }
                {twitterAccounts.length < 3 && filtered_account === 'instagram' &&
                    <InstagramConnectButton/>
                }
            </div>
        );
    } else {
        return (
            <>
                <Paper elevation={2} color='primary' variant="outlined">
                    You haven't connected to Twitter yet. Click the button below to connect your account
                </Paper>
                {twitterAccounts.length < 3 && filtered_account === 'twitter' && <TwitterConnectButton/> }
                {twitterAccounts.length < 3 && filtered_account === 'linkedin' && <LinkedInConnectButton/> }
                {twitterAccounts.length < 3 && filtered_account === 'instagram' && <InstagramConnectButton/> }
            </>
        )
    }
};

export default SocialAccountsDisplay;