import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import {useAuth} from "../providers/AuthProvider";
import ImageGalleryDialog from "./ImageGalleryDialog";
import Box from "@mui/material/Box";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PermMediaSharpIcon from '@mui/icons-material/PermMediaSharp';
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {StaticDateTimePicker} from '@mui/x-date-pickers/StaticDateTimePicker';
import dayjs from "dayjs";
import {useStatus} from "../providers/MsgStatusProvider";
import styled from "@emotion/styled";
import {Tooltip} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    //padding: 16px; // theme.spacing(2)
    width: 70vw;
    align-self: center;
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 32px;

`;

const PostImgPreview = styled.img`
    height: 180px;
    object-fit: initial;
    width: 200px;
`

const InputWrapper = styled.div`
    display: flex;
    gap: 24px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const ActionButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;

const AccountButtons = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StyledAvatar = styled(Avatar)`
    filter: ${(props) => (props.isGrayscale ? 'grayscale(100%)' : 'none')};
    width: 40px;
    height: 40px;    
  ${props => props.selected && `
    border: 3px solid #4CAF50;  // Green color, you can adjust as needed
    box-sizing: border-box;
  `}     
`;
const AvatarWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

// const StyledAvatar = styled(Avatar)`
//   width: 40px;
//   height: 40px;
// `;

const IconOverlay = styled.div`
  position: absolute;
  bottom: -5px;
  right: -5px;
  background-color: rgba(255,255,255, 0.5);
  border-radius: 50%;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
// const platforms = ["Facebook", "Twitter", "Instagram", "LinkedIn"];
const currentDate = new Date();
const formattedDate = currentDate.toISOString().split('T')[0];

const getPlatformIcon = (platform) => {
  switch (platform.toLowerCase()) {
    case 'twitter':
      return <TwitterIcon fontSize="small" />;
    case 'linkedin':
      return <LinkedInIcon fontSize="small" />;
    case 'instagram':
      return <InstagramIcon fontSize="small" />;
    default:
      return null;
  }
};

const ComposePost = () => {
    const auth = useAuth();
    const platforms = ["Twitter", "BlueSky"];
    // const classes = useStyles();

    const [activePlatform, setActivePlatform] = useState(platforms[0]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [userPlatforms, setUserPlatforms] = useState([]);
    const [open, setOpen] = useState(false);
    const [postImg, setPostImg] = useState({
        url: '',
        type: 'png'
    });
    const [postDate, setPostDate] = useState('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [scheduledDate, setScheduledDate] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [imgAltText, setImgAltTextimgAltText] = useState(null);
    const {setStatusType, setStatusMessage, setIsAlertOpen} = useStatus();

    // Initialize a state object with each platform as a key
    const [postTexts, setPostTexts] = useState(
        userPlatforms.reduce((acc, platform) => {
            acc[platform] = "";
            return acc;
        }, {})
    );

    console.log(postTexts)

    useEffect(() => {
        const platforms = auth.user[0].userprofile.social_accounts.map(account => account.platform);
        const uniquePlatforms = [...new Set(platforms)]; // Get unique platforms
        setUserPlatforms(uniquePlatforms);

        if (uniquePlatforms.length > 0) {
            setActivePlatform(uniquePlatforms[0]); // Set the first platform as the active platform
        }
    }, [auth]);

    const handleDateChange = (date) => {
        setPostDate(date);
    };

    const handlePlatformClick = (platform) => {
        setActivePlatform(platform);
        setSelectedAccounts('');
    };

    const handleInputChange = (e, accountId) => {
        const updatedAccounts = selectedAccounts.map(account => {
            if (account.userId === accountId) {
                return {
                    ...account,
                    message: e.target.value
                };
            }
            return account;
        });
        setSelectedAccounts(updatedAccounts);

        // Update the message of the currently selected account
        if (currentAccount && currentAccount.userId === accountId) {
            setCurrentAccount({
                ...currentAccount,
                message: e.target.value
            });
        }
    };


    const handleImageAttach = () => {
        // Handle image attachment
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePostNow = () => {
        const activeAccounts = selectedAccounts.filter(account => account.message);
        const formDataArray = [];

        for (const account of activeAccounts) {

            let formData = {
                post_text: account.message,
                is_scheduled_post: false,
                social_account_id: account.userId,
                platform: account.platform,
                image_url: postImg.url,
                media_type: postImg.type,
                img_alt_text: imgAltText
            }

            formDataArray.push(formData);
        }
        const url = process.env.REACT_APP_API_BASE_URL + 'post/posts/';
        fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.token}`
            },
            body: JSON.stringify(formDataArray)
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setIsAlertOpen(true);
                    setStatusMessage(data.message);
                    setStatusType('success')
                } else {
                    setIsAlertOpen(true);
                    setStatusMessage(data.message || data.detail);
                    setStatusType('error')
                }
            })
            .catch((error) => {
                console.log(error)
                setIsAlertOpen(true);
                setStatusMessage('error.detail');
            });
        // }
    };


    const handleSchedulePost = () => {
        // Handle scheduling post
        // const localDate = dayjs.utc(postDate).local();
        // const formattedDate = postDate.toISOString();
        // const formData = new FormData();
        // formData.append('message', postTexts['twitter']);
        // formData.append('account_id', selectedAccounts);
        // formData.append('img_url', postImg ? postImg : " ");
        // formData.append('scheduled_post_date', scheduledDate);

        const activeAccounts = selectedAccounts.filter(account => account.message);
        const formDataArray = [];

        for (const account of activeAccounts) {

            let formData = {
                post_text: account.message,
                is_scheduled_post: true,
                scheduled_post_date: postDate,
                social_account_id: account.userId,
                platform: account.platform,
                image_url: postImg.url,
                media_type: postImg.type,
                img_alt_text: imgAltText
            }

            formDataArray.push(formData);
        }
        console.log('data Type: ', typeof formDataArray)
        const url = process.env.REACT_APP_API_BASE_URL + 'post/tweet/scheduled/';
        fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.token}` // include authentication token in headers
            },
            body: JSON.stringify(formDataArray)
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setIsAlertOpen(true);
                    setStatusMessage(data.message);
                    setStatusType('success')
                } else {
                    // setIsAlertOpen(true);
                    // setStatusMessage(data);
                    // setStatusType('error')
                }
            })
            .catch((error) => {
                console.error(error)
                // setIsAlertOpen(true);
                // setStatusMessage(error);
                // setStatusType('error')
            });
    };

    const handleAccountChange = (accountId, username, platform) => {
        const accountIndex = selectedAccounts.findIndex(account => account.userId === accountId);

        if (accountIndex > -1) {
            // Set the current account for editing
            setCurrentAccount(selectedAccounts[accountIndex]);
        } else {
            const newAccount = {
                userId: accountId,
                username,
                isActive: true,
                platform: platform,
                message: ''
            };
            setSelectedAccounts([...selectedAccounts, newAccount]);

            // Set the current account for editing
            setCurrentAccount(newAccount);
        }

    };

    const handleAccountDoubleClick = (accountId) => {
        const newSelectedAccounts = selectedAccounts.filter(account => account.userId !== accountId);
        setSelectedAccounts(newSelectedAccounts);

        // Unset the current account for editing if it's the one being removed
        if (currentAccount && currentAccount.userId === accountId) {
            setCurrentAccount(null);
        }
    };

    const isAnyMessageSet = () => {
        return selectedAccounts.some(account => account.message && account.message.trim() !== '');
    };

    return (
        <Root>
            <Box>
                <div>
                    <InputWrapper>
                        {postImg.url &&
                            <div>
                                <PostImgPreview src={postImg.url} alt="Generated Video"/>
                                {postImg.type === 'mp4' ? (
                                    <video width="100%" height="100%" controls>
                                        <source src={postImg.url}
                                                type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                ) : postImg.type === 'gif' ? (
                                    <img src={postImg.url} alt="Generated GIF"
                                         style={{width: '100%', height: '100%'}}/>
                                ) : (
                                    <img height="100%" width="100%"
                                         src={postImg.url} alt="Generated Image"/>
                                )}
                                <InputWrapper>
                                    <TextField
                                        label='Image Description'
                                        variant="outlined"
                                        fullWidth
                                        value={imgAltText ? imgAltText : ''}
                                        onChange={(e) => setImgAltTextimgAltText(e.target.value)}
                                    />
                                </InputWrapper>

                            </div>}
                        <div id='newPost' style={{width: '100%'}}>
                            <Box>
                                {auth.user[0].userprofile.social_accounts.map((account, index) => (
                                    <Tooltip title={`${account.platform.toLocaleUpperCase()}: ${account.username}`} key={index}>
                                        <IconButton
                                            onDoubleClick={() => handleAccountDoubleClick(account.user_id)}
                                            onClick={() => handleAccountChange(account.user_id, account.username, account.platform)}
                                            size="large">
                                            <AvatarWrapper>
                                                <StyledAvatar
                                                    alt={account.username}
                                                    selected={selectedAccounts.some(acc => acc.userId === account.user_id)}
                                                    style={{filter: selectedAccounts.some(acc => acc.userId === account.user_id) ? 'none' : 'grayscale(100%)'}}
                                                    src={account.account_img}/>
                                                <IconOverlay>
                                                  {getPlatformIcon(account.platform)}
                                                </IconOverlay>
                                            </AvatarWrapper>
                                        </IconButton>
                                    </Tooltip>
                                ))}
                            </Box>
                            <TextField
                                label={currentAccount ? 'Post as ' + currentAccount.username + ' to  ' + currentAccount.platform : 'Select an account to create a post'}
                                multiline
                                rows={6}
                                disabled={!currentAccount}
                                variant="outlined"
                                fullWidth
                                value={currentAccount ? currentAccount.message : ''}
                                onChange={(e) => handleInputChange(e, currentAccount.userId)}
                            />
                        </div>


                    </InputWrapper>
                    <ActionButtons>
                        <Box>

                            {/*<Button disabled={postImg ? true : false} color="primary" startIcon={<PermMediaSharpIcon/>}*/}
                            <Button color="primary" startIcon={<PermMediaSharpIcon/>}
                                    onClick={handleImageAttach}>
                                {postImg ? 'Edit Media' : 'Add Media'}
                            </Button>
                            {/*<FormControlLabel control={<Checkbox defaultChecked/>} label="Create Multiple Post"/>*/}
                        </Box>
                        <Box display="flex">
                            <Box display='flex'>
                                {showCalendar === false && (
                                    <Box>
                                        <Button style={{marginRight: '16px'}} variant="outlined"
                                                disabled={postDate !== '' || !isAnyMessageSet()}
                                                startIcon={<CalendarTodayIcon/>} color="primary"
                                                onClick={() => setShowCalendar(true)}>
                                            {postDate === '' ? "Schedule Post" : `${dayjs(postDate).format('ddd, MMMM D, YYYY hh:mm A')}`}
                                        </Button>
                                    </Box>
                                )}

                                <>
                                    {showCalendar && (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TextField
                                                id="datetime-local"
                                                defaultValue={dayjs(new Date())}
                                                disablePast={true}
                                                label="Schdule Post "
                                                type="datetime-local"
                                                sx={{width: 250}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={date => {
                                                    const localDate = dayjs(date);
                                                    const localISOString = localDate.format();
                                                    setPostDate(localISOString);
                                                }}

                                            />
                                        </LocalizationProvider>
                                    )}
                                    {postDate && (
                                        <>
                                            <Button disabled={!isAnyMessageSet()}
                                                    variant="contained" color="primary" onClick={handleSchedulePost}>
                                                Schedule Post
                                            </Button>
                                            <Button variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        setPostDate('')
                                                        setShowCalendar(false)
                                                    }}>
                                                Clear Date
                                            </Button>
                                        </>
                                    )}
                                    <Button variant="contained"
                                            size="small"
                                            disabled={postDate !== '' || !isAnyMessageSet()}
                                            color="primary"
                                            onClick={handlePostNow}>
                                        Post Now
                                    </Button>
                                </>
                            </Box>
                        </Box>
                    </ActionButtons>
                </div>

                <ImageGalleryDialog open={open} onClose={handleClose} setPostImg={setPostImg} postImg={postImg}/>

            </Box>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Schedule Post
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticDateTimePicker
                            onChange={date => {
                                const localDate = dayjs(date);
                                const localISOString = localDate.format();
                                setPostDate(localISOString);
                            }}

                            orientation="landscape"/>
                    </LocalizationProvider>
                </Box>
            </Modal>
        </Root>
    );
};

export default ComposePost;
