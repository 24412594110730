import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {sha256} from 'js-sha256';

import {EditableText} from "@blueprintjs/core";
// import makeStyles from '@mui/styles/makeStyles';
import {useAuth} from "../providers/AuthProvider";
import styled from "@emotion/styled";

function generateCustomId(userId, email, timestamp) {
    const s = `${userId}-${email}-${timestamp}`;
    const hash = sha256(s);
    return hash;
}

const ErrorText = styled.div`
  border: 1px solid red;

  &::after {
    content: 'Your error message here';
    color: red;
    display: block;
  }
`;

export const PageControls = observer(({
                                          page,
                                          xPadding,
                                          yPadding,
                                          templateName,
                                          setTemplateName,
                                          hasError,
                                          handleError,
                                          store
                                      }) => {
    // const classes = useStyles();
    const auth = useAuth();
    const [localTemplateName, setLocalTemplateName] = useState(templateName);


    const templateId = generateCustomId(auth.user[0].id, new Date().getTime());

    // Set templateName on page load based on store
    useEffect(() => {
        const initialTemplateName = store.toJSON().pages[0].custom?.name;
        if (initialTemplateName) {
            setTemplateName(initialTemplateName);
            setLocalTemplateName(initialTemplateName);
        }
    }, []); // The empty array [] makes it run on mount only.


    return (
        <>
            <div
                style={{
                    position: "absolute",
                    top: yPadding - 35 + "px",
                    left: xPadding + "px",
                    ...(localTemplateName === '' && {border: '1px solid #ff6a6a'})
                }}
            >
                <EditableText
                    id="numbers"

                    // we can use custom data to store page name into store
                    placeholder="Template Name"
                    value={localTemplateName}
                    onChange={(val) => {
                        setLocalTemplateName(val);
                        console.log(localTemplateName, 'localTemplateName')
                        console.log(localTemplateName.length, 'localTemplateName.length')
                        console.log(localTemplateName.length >= 1, 'check')
                    }}
                    onConfirm={(val) => {
                        setTemplateName(val);
                        console.log(localTemplateName, 'confirm localTemplateName')
                        console.log(localTemplateName.length, 'CONFIRM localTemplateName.length')
                        console.log(localTemplateName.length >= 1, 'CONFIRM check')
                        page.set({
                            custom: {
                                ...page.custom,
                                name: val,
                                customId: store.toJSON().pages[0].custom?.customId ?? templateId
                            }
                        });

                    }}
                />
            </div>

        </>
    );
});
