import React from 'react';
import {Grid} from '@mui/material';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const CheckEmailPage = () => {

    return (
        <Grid container style={{minHeight: '73vh'}}>
            <Grid item sm={12}>
                <Typography align="center" component="h1" variant="h4">
                    Please check your email for next steps
                </Typography>
                <Typography align="center" component="p" variant="body1">
                    To help us confirm you are you, we send your email a confirmation code as a link to your email.
                </Typography>
                <Typography align="center" component="p" variant="body2">
                    If you're running into trouble, please contract us at <Link href="mailto:bugs@onsolosocial.com">bugs@onsolosocial.com</Link>.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default CheckEmailPage;
