import React, { createContext, useState, useContext } from 'react';

const MsgStatusContext = createContext();

export const StatusProvider = ({ children }) => {
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState(''); // success, error, info
  const [isAlertOpen, setIsAlertOpen] = useState(false);


  return (
    <MsgStatusContext.Provider
        value={{
          setStatusMessage,
          setIsAlertOpen,
          setStatusType,
          statusMessage,
          isAlertOpen,
          statusType }}
    >
      {children}
    </MsgStatusContext.Provider>
  );
};

export const useStatus = () => {
  return useContext(MsgStatusContext);
};
