import { createMuiTheme, adaptV4Theme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles'

// A custom theme for this app
const palette = {
  common: {
    black: '#000120',
    white: '#fff',
  },
  grey: {
    '40': '#f6f6f6',
    '50': '#EFE7DA',
    '80': '#eff1f6',
    '100': '#5a5855',
    '200': '#363638',
    '300': '#212121',
  },
  primary: {
    // main: '#FDC727',
    main: "#000120",

  },
  secondary: {
    // main: '#04BA70',
    main: '#FDC727',
  },
  error: {
    main: '#F67066',
  },
  warning: {
    main: '#F6BC66',
  },
  info: {
    main: '#668EF6',
  },
  success: {
    main: '#8FD359',
  },
  background: {
    default: '#fff  ',
  },
  link: {
    main: '#008000',
  },
};

const theme = createTheme(adaptV4Theme({
  palette: palette,
  typography: {
    fontFamily: [
      'Lato', // fallback
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: 'Uncut Sans', // specify for h1, if it's different from the general font family
      fontSize: '3.5rem', // adjust size as per requirement
      fontWeight: "bold"
    },
    h2: {
      fontFamily: 'Uncut Sans', // specify for h2, if it's different
      fontSize: '3rem',
      fontWeight: "bold"
    },
    h3: {
      fontFamily: 'Uncut Sans', // specify for h2, if it's different
      fontSize: '2.5rem',
      fontWeight: "bold"
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: palette.common.black,
        textDecoration: 'underline'
      },
    },
    MuiTextField: {
      root: {
          "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: "black", // Override the default border color here
            // Add more styles as needed
          },
          "&$focused $notchedOutline": {
            borderColor: "black", // Override the border color when the input is focused
          },
          marginBottom: '24px'
        },
    },
    MuiButton: {
      // styleOverrides: {
        root: {
          // backgroundColor: palette.common.black,
          textTransform: 'capitalize',
          fontWeight: 'bold',
        },
        primary: {
          color: palette.common.white,
          backgroundColor: palette.common.black
        },
        containedPrimary: { // Styles for variant='contained'
          // your styles here
            backgroundColor: palette.common.black,
            color: palette.common.white,
            '&:hover': {
              backgroundColor: palette.grey["200"], // dark gray on hover
            },
        },
        outlined: { // Styles for variant='outlined'
          // your styles here
          color: palette.common.black,
          primary: {
            border: palette.common.black + ' 1px solid',
          }
        },
        text: { // Styles for variant='text'
          // your styles here
          // primary: {
          color: palette.common.black + ' !important',
          // }
        },
      // }
    },
    MuiInputLabel: {
      outlined: {
        '&$focused': {
          color: palette.common.black, // Focus state label color
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:not($focused):not($error) $notchedOutline': {
          borderColor: palette.grey["100"], // Set the default border color
        },
        '&$focused $notchedOutline': {
          borderColor: palette.common.black, // Focus state border color
          color: palette.common.black, // Focus state border color
        },
      },
      notchedOutline: {}, // Empty class required for nesting
      focused: {}, // Empty class required for nesting
      error: {}, // Empty class required for nesting
    }
  },
}));

export default theme;
