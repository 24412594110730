import React, {useEffect, useState} from 'react';
// import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from "@mui/material/Alert";
import FormControl from '@mui/material/FormControl';
import {useAuth} from "../providers/AuthProvider";
import {useNavigate} from "react-router";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {useStatus} from "../providers/MsgStatusProvider";
import Link from "@mui/material/Link";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// const useStyles = makeStyles((theme) => ({
//   nopadding: {
//     marginTop: '-64px',
//     paddingTop: '-16px',
//     marginBottom: '0px',
//     height: '80vh'
//   },
//   container: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '80vh', // 100% of viewport height
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//   },
//   button: {
//     margin: theme.spacing(1),
//   },
// }));

function LoginForm() {
    // const classes = useStyles();
    const auth = useAuth();
    const {statusType, setStatusMessage, setIsAlertOpen, setStatusType} = useStatus();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        await auth.login(username, password, timezone);
    };

    useEffect(() => {
        if (auth.user.length > 0) {
            navigate('/dashboard', {replace: true});
        }
    }, [auth.user]);

    useEffect(() => {
        if (auth.errorMessage) {
            //   setStatusMessage(auth.errorMessage);
            //   setIsAlertOpen(true);
            //   alert('message');
            if (auth.errorMessage['non_field_errors']) {
                console.log(auth.errorMessage['non_field_errors'][0]);
                setStatusMessage(auth.errorMessage['non_field_errors'][0])
                setStatusType('error');
                setIsAlertOpen(true)
            }

        } else {
            setStatusMessage('');
            setIsAlertOpen(false);
        }
    }, [auth.errorMessage]);


    function handleRememberMeChange() {

    }

    return (
        //{/*<Grid container className={classes.nopadding}>*/}
        <Grid container>
            <Grid item xs={12} sm={4}>
                {/* Left side */}
                <div>
                    <FormControl variant="standard">
                        {auth.isAuthenticated ? (
                            <Typography variant="h3" align="center">
                                You are already logged in
                            </Typography>
                        ) : (<>
                                <Typography variant="h1" align="center">
                                    Login
                                </Typography>
                                {auth.errorMessage?.length > 0 &&
                                auth.errorMessage.map((error, index) => (
                                    <Alert key={index} severity={statusType}>
                                        {error}
                                    </Alert>
                                ))}

                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="username"
                                        label="Email"
                                        // className={classes.textField}
                                        type="text"
                                        value={username}
                                        onChange={(event) => setUsername(event.target.value)}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="password"
                                        label="Password"
                                        // className={classes.textField}
                                        type="password"
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={rememberMe}
                                                onChange={handleRememberMeChange}
                                                name="rememberMe"
                                                color="primary"
                                            />
                                        }
                                        label="Remember me"
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth={true}
                                        // className={classes.button}
                                        type="submit"
                                    >
                                        Log in
                                    </Button>
                                    <Link href="/forgot-password" variant="body2">
                                        Forgot password?
                                    </Link>
                                    <Link href="/create-account" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </form>
                            </>
                        )}
                    </FormControl>
                </div>
            </Grid>
            <Grid item xs={12} sm={8}>
                {/* Right side */}
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="115%"
                >
                    <img src="your-image-url-here" alt="Your description here"/>
                </Box>
            </Grid>
        </Grid>
    );
}

export default LoginForm;
