import React, { createContext, useContext, useState } from 'react';

const ShoppingCartContext = createContext([]);

const ShoppingCartProvider = ({ children }) => {
  const [items, setItems] = useState([]);

  const addItem = item => {
    setItems([...items, item]);
    console.log(item)
  };

  const removeItem = itemId => {
    setItems( items.filter(item => item.id !== itemId.id) );
  };

  return (
    <ShoppingCartContext.Provider value={{ items, addItem, removeItem }}>
      {children}
    </ShoppingCartContext.Provider>
  );
};

const useShoppingCart = () => useContext(ShoppingCartContext);

export { ShoppingCartProvider, useShoppingCart };
