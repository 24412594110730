import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types'
// import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import dayjs from 'dayjs'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import TemplateCard from "../components/TemplateCard";
import SocialPostCard from "../components/SocialPostCard";
import {Calendar, dayjsLocalizer, momentLocalizer} from 'react-big-calendar'
import {useAuth} from "../providers/AuthProvider";
import styled from "@emotion/styled";

const localizer = dayjsLocalizer(dayjs);

const MessageWrapper = styled('div')(({theme}) => ({
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.grey["80"],
    color: theme.palette.grey["100"],
    display: 'flex',
    borderRadius: theme.spacing(2),
    // height: '80vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 200,
//   },
//   button: {
//     margin: theme.spacing(1),
//   },
//   templateCard: {
//     margin: theme.spacing(2),
//   },
//   eventWrapper: {
//     backgroundColor: 'blue', // set the color as per your preference
//     color: 'white',  // set the color as per your preference
//     // add other styles as required
//   },
//     eventDetail: {
//       backgroundColor: 'red'
//     }
// }));

function DashboardPage() {
    // const classes = useStyles();
    const auth = useAuth();
    const posts_cal_events = auth.user[0].posts_cal_events;

    const newData = posts_cal_events?.map(event => ({
        ...event,
        'title': event.title,
        id: event.post_id,
        start: new Date(event.start),
        end: new Date(event.end),
    }));


    const [templates, setTemplates] = useState([]);
    const [post, setPosts] = useState([]);
    let newPull = false;
    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + 'templates/';
        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
                },
            }
        )
            .then(response => response.json())
            .then(data => {
                setTemplates(data);
            })
            .catch(error => console.error(error));
    }, [newPull]);

    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + 'post/posts/';
        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
                },
            }
        )
            .then(response => response.json())
            .then(data => {
                setPosts(data);
                console.log(data)
            })
            .catch(error => console.error(error));
    }, [newPull]);


    function createPost() {

    }

    function createTemplate() {
        // redirect to the new page
        // history.push('/demo');
    }

    // Define a custom event wrapper component
    const ColoredEventWrapper = ({children, event}) => {
        return React.cloneElement(children, {
            style: {
                ...children.props.style,
                backgroundColor: event.is_posted ? 'blue' : 'red',
            },
        });
    };

    const CustomEvent = ({event}) => (
        <div style={{backgroundColor: event.is_posted ? 'blue' : 'red'}} onClick={() => {
            alert(event.id)
        }}>
            <strong>{event.title}</strong>
            <p>{event.description}</p>
        </div>
    );

    function EventWrapper({event}) {
        // const classes = useStyles(); // Use the styles
        return (
            //*<span className={classes.eventWrapper}>*/}
            <span>
            <strong>{event.title}</strong>
            <p>{event.post_text}</p>
          </span>
        );
    }

    const {components} = useMemo(
        () => ({
            components: {
                event: CustomEvent,
            },
        }),
        []
    );
    const ColoredDateCellWrapper = ({children}) =>
        React.cloneElement(React.Children.only(children), {
            style: {
                backgroundColor: 'lightblue',
            },
        })
    return (
        <div>
            <Typography variant="h1" component='h1' gutterBottom>
                Welcome Back!
            </Typography>

            <Grid container spacing={3}>
                {posts_cal_events?.length > 0 ? (
                    <>
                        <Grid item xs={12}>
                            <Calendar
                                localizer={localizer}
                                events={newData}
                                components={{
                                    event: CustomEvent,
                                    eventWrapper: ColoredEventWrapper
                                }}
                                header={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: "dayGridMonth,timeGridWeek,timeGridDay"
                                }}
                                defaultView="month"
                                startAccessor="start"
                                defaultDate={new Date}
                                endAccessor="end"
                                views={['day', 'week', 'month']}
                                style={{height: 500}}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} height="60vh">
                            <Grid container display="flex" justifyContent="center">
                                <MessageWrapper width="100%">
                                    <Typography variant="h5">
                                        Create your first template and a post to see the magic of Solo Social
                                    </Typography>
                                </MessageWrapper>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
}

export default DashboardPage;
